import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FriendRequest, User } from '../models/user';
import Loading from '../components/Loading';
import TopNavBar from '../components/TopNavBar';
import { FaUser, FaEdit } from 'react-icons/fa';
import { useImageLoader } from '../hooks/useImageLoader';
import { useFriends } from '../hooks/useFriends';
import { useFriendRequests } from '../hooks/useFriendRequests';

interface UserProfilePageProps {}

const UserProfilePage: React.FC<UserProfilePageProps> = () => {
  const { username } = useParams<{ username: string }>();
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [friendStatus, setFriendStatus] = useState<boolean>(false);
  const { incomingRequests, sentRequests, loading: requestsLoading } = useFriendRequests();
  const [friendRequestSent, setFriendRequestSent] = useState<boolean>(false);
  const [friendRequestReceived, setFriendRequestReceived] = useState<boolean>(false);
  const { getFriendStatus } = useFriends();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!username) {
          navigate('/404');
          return;
        }
        const userData = await User.fetchUserByUsername(username);
        if (!userData) {
          navigate('/404');
          return;
        }
        setUser(userData);

        const currentUserData = await User.fetchCurrentUser();
        setCurrentUser(currentUserData);

        if (userData.user_id !== currentUserData.user_id) {
          const status = await getFriendStatus(userData.user_id);
          setFriendStatus((status && status.friended_at) ? true : false);

          const requestReceived = Array.isArray(incomingRequests) ? incomingRequests.some(
            (request) =>
              request.requester_id === userData.user_id &&
              request.status === 'pending'
          ) : false;
          setFriendRequestReceived(requestReceived);

          const requestSent = Array.isArray(sentRequests) ? sentRequests.some(
            (request) =>
              request.recipient_id === userData.user_id &&
              request.status === 'pending'
          ) : false;
          setFriendRequestSent(requestSent);
        }
      } catch (err) {
        setError('Failed to load user profile');
      } finally {
        setLoading(false);
      }
    };

    if (!requestsLoading) {
      fetchData();
    }
  }, [username, navigate, incomingRequests, requestsLoading]);

  const handleAddFriend = async () => {
    if (!user) return;
    try {
      await currentUser?.createFriendRequest(user.user_id);
      setFriendStatus(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAcceptFriendRequest = async () => {
    if (!user) return;
    const friendRequest = incomingRequests.find(request => request.requester_id === user.user_id);
    if (!friendRequest) return;
    await User.acceptFriendRequest(friendRequest);
    setFriendStatus(true);
  };

  const loadedImage = useImageLoader(user ? user.profile_picture_url : null);

  return (
    <div className="min-h-screen bg-gray-50">
      <TopNavBar />
      {loading ? (
        <div className="flex justify-center items-center py-20 min-h-screen bg-transparent">
          <Loading />
        </div>
      ) : error ? (
        <div className="text-red-500 text-center p-4">{error}</div>
      ) : user ? (
        <div className="container mx-auto pt-8 px-4">
          {/* Profile Card */}
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            {/* Cover Image */}
            <div className="h-32 bg-gradient-to-r from-gray-200 to-gray-300 relative">
              {/* Edit Profile Icon */}
              {currentUser && user && currentUser.user_id === user.user_id && (
                <Link
                  to="/editprofile"
                  className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 border rounded p-1"
                >
                  <FaEdit size={24} />
                </Link>
              )}
            </div>

            {/* Profile Info */}
            <div className="relative px-6 pb-6">
              {/* Profile Picture */}
              <div className="absolute -top-16 left-6">
                {loadedImage ? (
                  <img
                    src={loadedImage}
                    alt={`${user.first_name}'s profile`}
                    className="h-32 w-32 rounded-full border-4 border-white object-cover"
                  />
                ) : (
                  <div className="h-32 w-32 rounded-full bg-gray-200 flex items-center justify-center border-4 border-white">
                    <FaUser className="text-gray-400 text-4xl" />
                  </div>
                )}
              </div>

              {/* User Info */}
              <div className="pt-20">
                <div className="flex items-center justify-between">
                  <div>
                    <h1 className="text-3xl font-semibold text-gray-800">
                      {user.first_name} {user.last_name}
                    </h1>
                    <p className="text-gray-400 text-lg">@{user.username}</p>
                  </div>
                  {user.location && (
                    <div className="flex items-center text-gray-500">
                      <svg
                        className="h-5 w-5 mr-2 text-gray-400"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                        <path d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                      {user.location}
                    </div>
                  )}
                </div>

                {/* Tagline */}
                {user.tagline && (
                  <p className="mt-4 text-gray-500 italic">"{user.tagline}"</p>
                )}

                {/* Bio */}
                {user.bio && (
                  <div className="mt-6">
                    <h2 className="text-xl font-semibold text-gray-800 mb-2">About</h2>
                    <p className="text-gray-700 whitespace-pre-wrap">{user.bio}</p>
                  </div>
                )}

                {/* Friend Status or Add Friend Button */}
                {currentUser && user && currentUser.user_id !== user.user_id && (
                  <div className="mt-4 flex items-center">
                    {friendStatus ? (
                      <div className="inline-flex items-center px-4 py-2 bg-green-100 text-green-800 rounded-full">
                        <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" strokeWidth="2"
                          viewBox="0 0 24 24">
                          <path d="M5 13l4 4L19 7"></path>
                        </svg>
                        You are friends
                      </div>
                    ) : friendRequestReceived ? (
                      <button
                        className="inline-flex items-center px-4 py-2 bg-emerald-500 text-white font-semibold rounded-md shadow-md hover:bg-emerald-600 focus:outline-none"
                        onClick={handleAcceptFriendRequest}
                      >
                        Accept
                      </button>
                    ) : friendRequestSent ? (
                      <button
                        className="inline-flex items-center px-4 py-2 bg-indigo-500 text-white font-semibold rounded-md shadow-md"
                        disabled
                      >
                        Requested
                      </button>
                    ) : (
                      <button
                        className="inline-flex items-center px-4 py-2 bg-rose-500 text-white font-semibold rounded-md shadow-md hover:bg-rose-600 focus:outline-none"
                        onClick={handleAddFriend}
                      >
                        <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" strokeWidth="2"
                          viewBox="0 0 24 24">
                          <path d="M12 4v16m8-8H4"></path>
                        </svg>
                        Add Friend
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default UserProfilePage; 