import React, { useState, useRef, useEffect, useMemo } from 'react';
import { FaUserCircle, FaUserEdit, FaSignOutAlt, FaTimes, FaPlusCircle, FaChevronDown, FaUserPlus, FaFutbol, FaEllipsisV, FaBell, FaUser } from 'react-icons/fa';
import { useNavigate, Link } from 'react-router-dom';
import { User } from '../models/user';
import RedirectIfAuthenticated from './RedirectIfAuthenticated';
import Logo from './Logo';
import SearchBar from './SearchBar';
import { useFriendRequests } from '../hooks/useFriendRequests';

const TopNavBar: React.FC = () => {
	const navigate = useNavigate();
	const [showDropdown, setShowDropdown] = useState(false);
	const [showMobileSettings, setShowMobileSettings] = useState(false);
	const [showCreateDropdown, setShowCreateDropdown] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const mobileSettingsRef = useRef<HTMLDivElement>(null);
	const createDropdownRef = useRef<HTMLDivElement>(null);
	const [currentUsername, setCurrentUsername] = useState<string>('');
	const [showNotifications, setShowNotifications] = useState(false);
	const [notifications, setNotifications] = useState<{ id: number; message: string; viewed: boolean; }[]>([]);
	const notificationsRef = useRef<HTMLDivElement>(null);

	const { incomingRequests, sentRequests, loading } = useFriendRequests();

	const handleSignOut = async () => {
		try {
			const currentUser = await User.fetchCurrentUser();
			
			await currentUser.signOut();
		} catch (error) {
			console.error('Sign out failed', error);
		}
		navigate('/signin');
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (createDropdownRef.current && !createDropdownRef.current.contains(event.target as Node)) {
				setShowCreateDropdown(false);
			}
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setShowDropdown(false);
			}
			if (notificationsRef.current && !notificationsRef.current.contains(event.target as Node)) {
				setShowNotifications(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	useEffect(() => {
		async function fetchUsername() {
			try {
				const currentUser = await User.fetchCurrentUser();
				setCurrentUsername(currentUser.username);
			} catch (error) {
				console.error('Failed to fetch user', error);
			}
		}
		fetchUsername();

		async function fetchNotifications() {
			try {
				const fetchedNotifications = [];
				setNotifications(fetchedNotifications);
			} catch (error) {
				console.error('Failed to fetch notifications', error);
			}
		}
		fetchNotifications();
	}, []);

	const closeMobileSettings = () => {
		setShowMobileSettings(false);
	};

	const logoUrl = 'https://imagedelivery.net/60EBju9pR70EGeFkQ4RoMw/2af30e91-2bd6-4d9b-fb3c-b0b9d8bac800/240x240';

	const logo = useMemo(() => <Logo logoUrl={logoUrl} />, [logoUrl]);

	const handleNotificationClick = (requestId: string) => {
		console.log('Friend request clicked:', requestId);
		setShowNotifications(false);
	};

	return (
		<div className="bg-white shadow-md py-3 px-4 md:px-6 flex items-center justify-between relative">
			{RedirectIfAuthenticated && <RedirectIfAuthenticated />}
			<Link to="/dashboard" className="flex items-center cursor-pointer">
				{logo}
				<h1 className="text-xl font-bold text-gray-800 -ml-2">Kickoff</h1>
			</Link>

			<SearchBar />

			{/* Right Side Menu */}
			<div className="flex items-center space-x-4">
				{/* Create Dropdown */}
				<div className="relative" ref={createDropdownRef}>
					<button
						className="flex items-center space-x-2 text-rose-500 hover:text-rose-600 px-1 py-2 rounded-full transition duration-300"
						onClick={() => setShowCreateDropdown(!showCreateDropdown)}
					>
						<FaPlusCircle size={30} />
						{/* <span className="align-middle">Create</span> */}
						{/* <FaChevronDown size={14} /> */}
					</button>
					{showCreateDropdown && (
						<div
							className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-50 overflow-hidden"
						>
							<Link
								to="/create-team"
								className="flex items-center px-4 py-3 text-gray-700 hover:bg-indigo-50 transition duration-300"
							>
								<FaUserPlus className="mr-3 text-indigo-600" size={18} />
								<span className="align-middle">New Team</span>
							</Link>
							<Link
								to="/create-game"
								className="flex items-center px-4 py-3 text-gray-700 hover:bg-rose-50 transition duration-300"
							>
								<FaFutbol className="mr-3 text-rose-600" size={18} />
								<span className="align-middle">New Game</span>
							</Link>
						</div>
					)}
				</div>

				{/* Notification Bell Icon */}
				<div className="relative" ref={notificationsRef}>
					<button
						className="relative flex items-center text-gray-600 hover:text-gray-700 py-2 rounded-full transition duration-300"
						onClick={() => setShowNotifications(!showNotifications)}
					>
						<FaBell size={24} />
						{/* Show a red dot if there are unviewed friend requests */}
						{incomingRequests.length > 0 && (
							<span className="absolute top-0 right-0 inline-block w-2 h-2 bg-red-500 rounded-full"></span>
						)}
					</button>
					{showNotifications && (
						<div className="absolute right-0 mt-2 w-80 max-h-96 bg-white border border-gray-200 rounded-lg shadow-lg z-50 overflow-auto">
							{incomingRequests.length > 0 ? (
								incomingRequests.map(request => (
									<Link
										to={`/u/${request.user.username}`}
										key={request.request_id}
										className="px-4 py-3 text-gray-700 transition duration-300 cursor-pointer hover:bg-gray-100 flex items-center space-x-3 border-b border-gray-100 last:border-b-0"
										onClick={() => handleNotificationClick(request.request_id)}
									>
										{/* User Avatar - Using a default avatar icon if no image */}
										<div className="flex-shrink-0">
											<FaUserCircle className="w-10 h-10 text-gray-400" />
										</div>
										
										<span>
											<span className="font-medium text-indigo-600">{request.user.username}</span>
											<span className="text-gray-600"> sent you a friend request</span>
										</span>
									</Link>
								))
							) : (
								<div className="px-4 py-3 text-gray-700">
									No notifications
								</div>
							)}
						</div>
					)}
				</div>

				{/* Profile Dropdown */}
				<div className="relative flex items-center" ref={dropdownRef}>
					{/* <Link
						to={`/u/${currentUsername}`}
						className="flex items-center space-x-2 text-indigo-600 hover:text-indigo-700 px-1 py-2 rounded-full transition duration-300"
					>
						<FaUserCircle size={32} /> 
					</Link> */}
					<button
						onClick={() => setShowDropdown(!showDropdown)}
						className="flex items-center justify-center text-indigo-600 hover:text-indigo-700 rounded-full transition duration-300 ml-1 mr-1"
					>
						<FaUserCircle size={30} />
						{/* <FaEllipsisV size={24} /> */}
					</button>

					{showDropdown && (
						<div
							onClick={() => setShowDropdown(false)}
							className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-50 overflow-hidden"
							style={{ top: '100%' }}
						>
							<Link
								to={`/u/${currentUsername}`}
								className="flex items-center px-4 py-3 text-gray-700 hover:bg-indigo-50 transition duration-300"
							>
								<FaUser className="mr-3 text-indigo-600" size={18} />
								<span className="align-middle">Profile</span>
							</Link>
							
							<button
								onClick={handleSignOut}
								className="w-full flex items-center px-4 py-3 text-gray-700 hover:bg-rose-50 transition duration-300"
							>
								<FaSignOutAlt className="mr-3 text-rose-600" size={18} />
								<span className="align-middle">Sign Out</span>
							</button>
						</div>
					)}
				</div>

			</div>

			{/* Mobile Menu Button */}
			{/* <div className="md:hidden">
				<button
					onClick={() => setShowMobileSettings(true)}
					className="flex items-center space-x-2 bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded-full transition duration-300"
				>
					<FaUserCircle size={20} />
					<span className="align-middle">Profile</span>
				</button>
			</div> */}

			{/* Mobile Settings Modal */}
			{showMobileSettings && (
				<div className="fixed inset-0 bg-black bg-opacity-50 flex items-end md:hidden z-50">
					<div className="absolute inset-0" onClick={closeMobileSettings}></div>
					<div ref={mobileSettingsRef} className="bg-white rounded-t-lg w-full p-4 animate-slide-up relative">
						<h2 className="text-lg font-semibold text-gray-800 mb-4 text-center">Settings</h2>
						<div className="space-y-4">
							<button
								onClick={() => {
									navigate('/profile');
									closeMobileSettings();
								}}
								className="w-full flex items-center justify-center px-4 py-3 text-gray-700 hover:bg-indigo-50 transition duration-300 rounded"
							>
								<FaUserCircle className="mr-3 text-indigo-600" size={20} />
								<span className="align-middle">Profile</span>
							</button>
							<button
								onClick={() => {
									navigate('/editprofile');
									closeMobileSettings();
								}}
								className="w-full flex items-center justify-center px-4 py-3 text-gray-700 hover:bg-indigo-50 transition duration-300 rounded"
							>
								<FaUserEdit className="mr-3 text-indigo-600" size={20} />
								<span className="align-middle">Edit Profile</span>
							</button>
							<button
								onClick={() => {
									handleSignOut();
									closeMobileSettings();
								}}
								className="w-full flex items-center justify-center px-4 py-3 text-gray-700 hover:bg-rose-50 transition duration-300 rounded"
							>
								<FaSignOutAlt className="mr-3 text-rose-600" size={20} />
								<span className="align-middle">Sign Out</span>
							</button>
							<button
								onClick={closeMobileSettings}
								className="w-full flex items-center justify-center px-4 py-3 text-gray-700 hover:bg-gray-200 transition duration-300 rounded"
							>
								<FaTimes className="mr-3 text-gray-600" size={20} />
								<span className="align-middle">Cancel</span>
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default React.memo(TopNavBar);
